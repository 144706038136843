import { useState } from "react";
import "./App.css";
import Portal, { SearchResult } from "./portal/portal";
import axios from "axios";

const API_BASE =
  process.env.NODE_ENV === "production"
    ? "https://answerd.herokuapp.com/"
    : "http://localhost:8080/";
function App() {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  type SearchResponse = {
    score: number;
    text: string;
    title: string;
    channelTitle: string;
    videoId: string;
    startSecond: number;
  };
  function handleSearch(searchTerm: string) {
    setSearchResults([]);
    console.log(searchTerm);
    setIsLoading(true);
    axios
      .post(API_BASE + "api/answerd/search", {
        query: searchTerm,
      })
      .then(function (response) {
        const results = response.data.map((searchResponse: SearchResponse) => {
          const { score, videoId, text, title, channelTitle, startSecond } =
            searchResponse;

          return {
            searchMatchPercentage: Intl.NumberFormat("default", {
              style: "percent",
              maximumFractionDigits: 0,
            }).format(score),
            text,
            creator: channelTitle,
            videoId,
            title,
            startSecond,
          } as SearchResult;
        });
        console.log({ results });
        setIsLoading(false);
        setSearchResults(results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div className="App">
      <Portal
        results={searchResults}
        handleSearch={handleSearch}
        isLoading={isLoading}
      />
    </div>
  );
}

export default App;

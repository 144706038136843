import { useEffect, useState } from "react";
import TextTruncate from "react-text-truncate";
import YouTube, { YouTubeProps, YouTubePlayer } from "react-youtube";
import { toYoutubeThumbnail } from "./util/youtube-util";
import { useMediaQuery } from "react-responsive";

function SingleLineAuthorBadge({ name }: { name: string }) {
  return (
    <div className="w-fit items-center space-x-2 rounded-md bg-base-300 px-2.5 py-1.5">
      <span className="text-sm font-small text-secondary-content">Creator</span>
      <span className="text-md font-medium text-accent">{name}</span>
    </div>
  );
}

function MultiLineAuthorBadge({ name }: { name: string }) {
  return (
    <span className="flex flex-col w-96 rounded-md bg-base-300 px-2.5 py-1.5 w-full">
      <div className="text-sm font-medium text-secondary-content">Creator</div>
      <div className="text-md font-medium text-accent">{name}</div>
    </span>
  );
}

function SearchMatchBadge({ percentage }: { percentage: string }) {
  return (
    <span
      className={`flex items-center justify-around rounded-md bg-base-300 px-2.5 py-1.5 mt-4 space-x-12`}
    >
      <div className="text-sm font-small text-secondary-content">
        Search Match
      </div>
      <div className="text-lg font-medium text-primary-content">
        {percentage}
      </div>
      <div className="text-sm font-small text-primary-content">
        High confidence
      </div>
    </span>
  );
}

export type SearchResult = {
  videoId: string;
  startSecond: number;
  title: string;
  text: string;
  creator: string;
  searchMatchPercentage: string;
};

function DisplayedResult({
  videoId,
  startSecond,
  title,
  text,
  creator,
  searchMatchPercentage,
  isXl,
}: SearchResult & { isXl: boolean }) {
  const opts: YouTubeProps["opts"] = {
    height: "384",
    width: "650",
    playerVars: {
      start: startSecond,
    },
  };
  const [player, setPlayer] = useState<YouTubePlayer>();
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    setPlayer(undefined);
    setHasPlayed(false);
  }, [videoId]);
  const ytDivClasses = isXl ? "" : "aspect-w-16 aspect-h-9";
  const ytIframeClasses = isXl ? "" : "w-full";
  return (
    <div className="bg-base-200 rounded-none flex xl:flex-row flex-col pt-6 pb-6 pr-2 pl-2">
      <YouTube
        opts={opts}
        videoId={videoId}
        className={ytDivClasses}
        iframeClassName={ytIframeClasses}
        onReady={(event) => {
          setPlayer(event.target);
        }}
        onPlay={() => {
          // why? start doesnt work well the first time, so we force it here.
          if (!hasPlayed) {
            player.seekTo(startSecond);
            setHasPlayed(true);
          }
        }}
      />
      <div className="pl-4 pr-4 pb-4 pt-4 text-white flex xl:flex-col lg:flex-row flex-col">
        <div>
          <h2 className="text-2xl">{title}</h2>
          <div className="mt-1 mb-4 text-secondary-content">
            <TextTruncate
              text={text}
              element="p"
              truncateText="…"
              line={3}
            ></TextTruncate>
          </div>
        </div>
        <div>
          <MultiLineAuthorBadge name={creator} />
          <SearchMatchBadge percentage={searchMatchPercentage} />
        </div>
      </div>
    </div>
  );
}

function MoreResultsList({
  moreResults,
  handleChangeVideo,
}: {
  moreResults: SearchResult[];
  handleChangeVideo: (clickedResult: SearchResult) => void;
}) {
  return (
    <ul className="border-none">
      {moreResults.map((result, i) => {
        const thumbnail = toYoutubeThumbnail(result.videoId);
        return (
          <li
            key={i}
            className="mt-4 cursor-pointer"
            onClick={() => handleChangeVideo(result)}
          >
            <div className="card card-side bg-base-100 shadow-lg h-32 rounded-md">
              <figure className="mb-4 flex-shrink-0 sm:mb-0 w-160 bg-base-200">
                <img
                  src={thumbnail}
                  alt={result.title}
                  className="object-fill"
                />
              </figure>
              <div className="card-body pl-4 pb-4 pt-4 fluid bg-base-200 pr-4">
                <h4 className="card-title truncate-custom text-primary-content">
                  {result.title}
                </h4>
                <TextTruncate line={1} text={result.text} />
                <div>
                  <SingleLineAuthorBadge name={result.creator} />
                </div>
              </div>

              <div className="flex bg-base-300 mb-4 sm:mb-0 flex-col items-center justify-center rounded-r-md">
                <div className="w-36 flex flex-col items-center justify-center text-primary-content">
                  <div className="text-sm font-small text-secondary-content">
                    Match
                  </div>
                  <div className="text-2xl font-medium">
                    {result.searchMatchPercentage}
                  </div>
                  <div className="text-sm font-small">High</div>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

function MobileMoreResultsList({
  moreResults,
  handleChangeVideo,
}: {
  moreResults: SearchResult[];
  handleChangeVideo: (clickedResult: SearchResult) => void;
}) {
  return (
    <ul className="border-none">
      {moreResults.map((result, i) => {
        const thumbnail = toYoutubeThumbnail(result.videoId);
        return (
          <li
            key={i}
            className="mt-4 cursor-pointer"
            onClick={() => handleChangeVideo(result)}
          >
            <div className="bg-base-100 shadow-lg rounded-md">
              <div className="flex flex-row">
                <figure>
                  <img
                    src={thumbnail}
                    height="36"
                    alt={result.title}
                    className="w-full"
                  />
                </figure>
                <div className="flex bg-base-300 flex-col items-center justify-center rounded-tr-md">
                  <div className="w-36 flex flex-col items-center justify-center text-primary-content">
                    <div className="text-sm font-small text-secondary-content">
                      Match
                    </div>
                    <div className="text-2xl font-medium">
                      {result.searchMatchPercentage}
                    </div>
                    <div className="text-sm font-small">High</div>
                  </div>
                </div>
              </div>
              <div className="pl-4 pb-4 pt-4 fluid bg-base-200 rounded-b-md">
                <div className="text-primary-content text-lg leading-6 mb-2">
                  <TextTruncate line={3} text={result.title} />
                </div>
                <div className="pb-2 text-sm">
                  <TextTruncate line={2} text={result.text} element="p" />
                </div>
                <div>
                  <SingleLineAuthorBadge name={result.creator} />
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

function ResultsDisplay({ results }: { results: SearchResult[] }) {
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });

  const [displayedResult, setDisplayedResult] = useState(results[0]);
  const [moreResults, setMoreResults] = useState(results.slice(1));
  function handleChangeVideo(result: SearchResult) {
    setDisplayedResult(result);
    const resultsWithoutDisplayedResult = results.filter((r) => r !== result);
    setMoreResults(resultsWithoutDisplayedResult);
  }

  const containerClasses = isMobile
    ? "container mx-auto mt-12"
    : "container mx-auto mt-12 pl-12 pr-12";
  return (
    <>
      <div className="bg-base-200">
        <div className={containerClasses}>
          <DisplayedResult
            videoId={displayedResult.videoId}
            title={displayedResult.title}
            text={displayedResult.text}
            creator={displayedResult.creator}
            searchMatchPercentage={displayedResult.searchMatchPercentage}
            startSecond={displayedResult.startSecond}
            isXl={isXl}
          />
        </div>
      </div>
      <div className="bg-base-100">
        <div className="container mx-auto  pl-12 pr-12">
          {isMobile ? (
            <MobileMoreResultsList
              moreResults={moreResults}
              handleChangeVideo={handleChangeVideo}
            />
          ) : (
            <MoreResultsList
              moreResults={moreResults}
              handleChangeVideo={handleChangeVideo}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default function Portal({
  results,
  handleSearch,
  isLoading,
}: {
  results: SearchResult[];
  handleSearch: (searchTerm: string) => void;
  isLoading: boolean;
}) {
  const [searchText, setSearchText] = useState("");
  return (
    <div>
      <div className="container mx-auto sm:px-6 lg:px-8">
        <h1 className="text-2xl">Ask Khan Academy Anything</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(searchText);
          }}
          className="flex border border-none rounded"
        >
          <input
            type="text"
            className="block w-full px-4 py-2 text-primary-content bg-base-200 rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            placeholder="How do I find the intercept of a function?"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            className="px-4 rounded btn btn-accent"
            onClick={() => handleSearch(searchText)}
          >
            Find
          </button>
        </form>
      </div>
      {isLoading && <div>LOADING</div>}
      {results.length > 0 && <ResultsDisplay results={results} />}
    </div>
  );
}
